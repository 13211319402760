<template>
  <page-layout title="Edit Profile" linkDefault="/profile" :backButton="true">
    <Loading v-if="loading" />
    <div v-else v-if="user" class="w-full flex-col items-center mt-5">
      <div class="w-full flex justify-start mx-5" :key="updateImage">
        <Avatar :user="user" size="large" />
        <div class="flex flex-col justify-end w-full my-6 mx-6">
          <p class="font-poppins font-bold text-xl">
            {{ firstname }} {{ lastname }}
          </p>
          <p class="font-poppins font-normal text-xs">{{ email }}</p>
        </div>
      </div>

      <div class="w-full flex justify-start mx-6 relative">
        <input type="file" class="absolute inset-0 z-50 opacity-0" accept="image/png, image/jpeg" @change="uploadPP" />
        <Button @click="save()" color="secondary" size="small" class="z-0">Change</Button>
      </div>

      <div class="flex flex-col w-full pl-5 pr-5 mb-4 mt-5">
        <p class="mx-2 font-poppins font-bold text-sm">Bio</p>
        <textarea class="
            max-w-3xl
            h-12
            pl-5
            pr-5
            border-2 border-gray-200
            bg-white
            rounded-3xl
            placeholder-gray-500
            w-full
             p-2.5
          " :value="bio" @change="set('bio', $event.target.value)" label="Bio" placeholder="Write down your bio"
          type="text" />
      </div>
      <hr />

      <div class="flex flex-col w-full pl-5 pr-5 mb-5 mt-3">
        <p class="mx-2 font-poppins font-bold text-sm">First Name</p>
        <textarea class="
            max-w-3xl
            h-12
            pl-5
            pr-5
            border-2 border-gray-200
            bg-white
            rounded-3xl
            placeholder-gray-500
            w-full
             p-2.5
          " :value="firstname" @change="set('firstname', $event.target.value)" label="First Name"
          placeholder="FirstName" type="text" />
      </div>

      <div class="flex flex-col w-full pl-5 pr-5 mb-5">
        <p class="mx-2 font-poppins font-bold text-sm">Last Name</p>
        <textarea class="
            max-w-3xl
            h-12
            pl-5
            pr-5
            border-2 border-gray-200
            bg-white
            rounded-3xl
            placeholder-gray-500
            w-full
             p-2.5
          " :value="lastname" @change="set('lastname', $event.target.value)" label="Last Name" placeholder="LastName"
          type="text" />
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col w-full pl-5 pr-5 mb-5">
          <p class="mx-3 font-poppins font-bold text-sm">University</p>
          <textarea :disabled="true" class="
              max-w-3xl
              h-12
              pl-5
              pr-5
              border-2 border-gray-200
              bg-white
              rounded-3xl
              placeholder-gray-500
              w-full
               p-2.5
            " :value="university" @change="set('university', $event.target.value)" label="university"
            placeholder="university" type="text" />
        </div>

        <div class="flex flex-col w-full pl-5 pr-5 mb-5">
          <p class="mx-3 font-poppins font-bold text-sm">Class of</p>
          <textarea class="
              max-w-3xl
              h-12
              pl-5
              pr-5
              border-2 border-gray-200
              bg-white
              rounded-3xl
              placeholder-gray-500
              w-full
               p-2.5
            " :value="classof" @change="set('classof', $event.target.value)" label="Last Name" placeholder="classof"
            type="text" />
        </div>
      </div>
      <!-- <horizontal-input :value="firstname" @change="set('firstname', $event)" label="First Name"
        placeholder="first name" type="text" />
      <horizontal-input :value="lastname" @change="set('lastname', $event)" label="Last Name" placeholder="last name"
        type="text" />
      <horizontal-input :value="university" @change="set('university', $event)" disabled label="University"
        placeholder="university" type="text" />
      <horizontal-input :value="classof" @change="set('classof', $event)" label="Class Of" placeholder="Class of"
        type="number" :min="16" :max="28" />
        <horizontal-input  :bio="true" :value="bio" @change="set('bio', $event)" label="Bio" placeholder="Bio" /> -->


      <!-- <link-tab
        icon="lock"
        label="Password"
        direct="chevron-right"
        to="https://tutoruu.com/account/update-password"
        routing="external"
      />
      <link-tab
        icon="log-out"
        label="Logout"
        direct="chevron-right"
        to="https://tutoruu.com/account/logout"
        @click="
          fireToast({
            text: 'Logging out...',
            type: 'confirmation',
          })
        "
        routing="external"
      />
      <div class="h-14 bg-sky"></div>
      <link-tab
        icon="help-circle"
        label="Help"
        direct="chevron-right"
        :to="`/help`"
      /> -->

      <div class="px-4 w-full fixed bottom-20">
        <Button @click="save" v-if="updated" shape="full">Save</Button>
      </div>
    </div>
  </page-layout>
</template>
<script>
import LinkTab from "../../components/LinkTab.vue";
import Loading from "../../components/Loading.vue";
import HorizontalInput from "../../components/HorizontalInput.vue";
import Button from "../../components/Feed/Button.vue";
import Avatar from "../../components/Feed/Avatar.vue";
import {
  uploadProfilePicture,
  updateCurrentUser,
} from "../../helpers/API/Core/User";
import detailLayout from "../../components/base/detailLayout.vue";
import pageLayout from "../../components/base/pageLayout.vue";

import { mapActions, mapMutations } from "vuex";
import { updateProfile } from "../../helpers/API/Feeds";

export default {
  components: {
    LinkTab,
    HorizontalInput,
    Button,
    Avatar,
    detailLayout,
    pageLayout,
    Loading
  },

  data() {
    return {
      user: null,
      firstname: "",
      lastname: "",
      university: "",
      classof: "",
      img_url: "",
      bio: "",
      updateImage: 0,
      updated: false,
      email: "",
      loading: true,
    };
  },
  created() {
    this.setUser();
  },

  methods: {
    ...mapMutations(["fireToast"]),
    ...mapActions(["completeQuest"]),
    async uploadPP(event) {
      await uploadProfilePicture(event);
    },

    async updatePP() {
      this.setUser();
      this.completeQuest("add profile picture");
      await updateCurrentUser({
        img_url: this.img_url,
      });
      await this.updateFeedUser();
      this.updateImage++;
    },

    set(prop, val) {
      this.updated = true;
      this[prop] = val;
    },
    setUser() {
      this.user = this.$store.state.user;

      // splitting name into firstname and lastname
      const name = this.user.name.split(" ");
      this.firstname = name[0];
      name.shift();
      this.lastname = name.join(" ");
      this.lastname = this.lastname.replace(/\s+/g, " ").trim();
      this.email = this.user.email;
      this.university = this.user.university.abbrev;
      this.classof = this.user.classOf;
      this.img_url = this.user.img_url;
      this.bio = this.user.bio;
      this.updateImage++;
      this.loading = false;
    },
    async save() {
      this.updated = false;
      if (this.bio !== this.user.bio)
        this.completeQuest("add bio");

      await updateCurrentUser({
        name: this.firstname + " " + this.lastname,
        classOf: this.classof,
        bio: this.bio,
      });
      await this.updateFeedUser();
    },
    async updateFeedUser() {
      await updateProfile({
        profile_picture: this.img_url,
        full_name: this.firstname + " " + this.lastname,
        bio: this.bio,
      });
    },
  },
  watch: {
    "$store.state.user.img_url": async function () {
      await this.updatePP();
    },
  },
};
</script>