<template>
    <div class="flex justify-between align-middle relative w-full p-5" @click="go">
        <div class="flex-none ">
   <vue-feather :type="icon">      </vue-feather>
 
</div>
<div class="flex-auto mx-3">
      <p class="text-sm leading-6 font-normal font-poppins " > {{label}} </p>
    </div>
    <div>
   <vue-feather :type="direct"></vue-feather>
    </div>
    </div>
</template>

<script>

export default{
props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    direct: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null
    },
    routing: {
      type: String,
      enum: ["push", "replace", "external"],
      default: "push"
    }
},
methods: {
  go() {
    if(!this.to) return
    
    if(this.routing !== "external") return this.$router[this.routing](this.to);

    window.location.href = this.to
  }
},
}

</script>