<template>
  <ion-page>
    <ion-header
      class="ion-no-border header transition duration-300 ease-in-out"
    >
      <ion-toolbar class="pb-2 pt-3">
        <ion-buttons slot="start">
          <ion-back-button
            v-if="title != 'Dashboard'"
            :default-href="linkDefault"
            text=" "
            color="black"
          ></ion-back-button>
        </ion-buttons>
        <ion-title
          v-if="title != 'Dashboard'"
          mode="ios"
          class="text-black text-center w-full text-lg font-poppins font-medium"
          >{{ title }}</ion-title
        >
        <img
          v-else
          class="w-52 -ml-6 -mt-8"
          :src="require('@/assets/logo-transparent.png')"
          alt=""
        />
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" overflow-scroll="true">
      <n-config-provider :theme-overrides="themeOverrides">
        <!-- <side-nav class="z-0" /> -->
        <div v-if="title != 'Results'">
          <slot />
        </div>
      </n-config-provider>
    </ion-content>
  </ion-page>
</template>

<script>
import SideNav from "../SideNav.vue";

import {
  IonButtons,
  IonBackButton,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/vue";
// import { useOsTheme, darkTheme, NConfigProvider } from "naive-ui";
import { NConfigProvider } from "naive-ui";

import { mapActions, mapMutations, mapState } from "vuex";
import { useRouter } from "vue-router";
import Chat from "../Bell.vue";
import Help from "../HelpSmall.vue";

export default {
  computed: {
    ...mapState(["username"]),
  },
  methods: {
    ...mapMutations(["setToken"]),
    ...mapActions(["get_user"]),
  },
  data() {
    return {
      themeOverrides: {
        common: {
          primaryColor: "#FF8303",
          blackColor: "#FF8303",
          successColor: "#00DC23",
          primaryColorHover: "#FF8303bb",
          primaryColorPressed: "#FF8303",
          baseColor: "#FFF",
          hoverColor: "#FF8303",
        },
      },
    };
  },
  props: {
    title: String,
    linkDefault: String,
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonBackButton,
    NConfigProvider,
    Chat,
    Help,
    SideNav,
  },
  setup() {
    // const osThemeRef = useOsTheme();
    const router = useRouter();
    return {
      // theme: computed(() => (osThemeRef.value === "dark" ? darkTheme : null)),
      // osTheme: osThemeRef,
      router,
    };
  },
};
</script>

<style>
/* ion-content {
  --ion-background-color: #fcf6eb;
} */
.ion-title {
  --color: #000;
}
.ion-back-button {
  --color: #000;
  --margin-start: 200px;
  --background: black;
}
.ion-toolbar {
  --padding-bottom: 20px;
}
</style>