<template>
  <div class="flex justify-between align-middle relative w-full p-5" :class="disabled ? 'bg-gray-50 opacity-50' : '' || bio ?'flex flex-col':''">
    <p class="text-sm leading-6 font-normal font-poppins">
      {{ label }}
    </p>
    <input v-if="!bio" class="
        bg-transparent
        text-right text-sm
        leading-6
        outline-none
        font-normal font-poppins
        truncate
        rounded-full
      "
      style="min-width: 60% !important max-width: 60% !important"
       v-model="value" @keyup="change" :placeholder="placeholder" :min="min" :max="max" :type="type"
      :disabled="disabled" />
      <div v-if="bio" >
        <textarea class="
          text-base
          font-normal
          p-2.5
          pr-4
          resize-none
          text-gray-700
          bg-white bg-clip-padding
          rounded
          my-2
          w-full
          h-12
          focus:border-orange focus:outline-none
        " placeholder="bio" @keyup="change" v-model="value"></textarea>
        </div>
  </div>
  <hr />
</template>

<script>
// TODO prefix missing
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    bio:{
       type: Boolean,
      default: false,
    },
  },
  methods: {
    change(event) {
      this.$emit("change", event.target.value)
    },
  },
  emits: ["change"],
};
</script>
